import { Box, Grid } from '@mui/material';
import { styled } from '@mui/material/styles';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';

import InfoTable from '../../../components/InfoTable/InfoTable';
import DraggableDialog from '../../../components/hooks/Dialog';
import ModalThree from '../../../components/Form/Modals/ModalThree';
import { useState } from 'react';
import Buttons from '../../../components/Form/Button/Button';
import TextField from '../../../components/Form/TextField/TextField';
import { capitalizeWords } from '../../../hooks/HelperFunctions';
import moment from 'moment-timezone';
import EnteryourPassword from './EnteryourPassword';
import PhoneComponent from '../../../components/Common/Functions';
import { countries } from '../../../assets/data/countries';
import useProtected from '../../../hooks/useProtected';
import { useGenerateQrCodeMutation } from '../../../redux/features/qrCode/qrCodeApi';
import { useChangePasswordMutation, useForgotPasswordMutation } from '../../../redux/features/users/userApi';
import { toast } from 'react-toastify';

const StyledReadyCell = styled(Box)(({ theme }) => ({
    display: 'flex',
    gap: '4px',
    alignItems: 'center',
}))



const UserOverview = ({ userDetails, isLoading = false }) => {

    const [generateQrCode, generateQrCodeoptions] = useGenerateQrCodeMutation();
    const [changePassword, changePasswordOptions] = useChangePasswordMutation()
    const [forgotPassword, forgotPasswordOptions] = useForgotPasswordMutation()

    useProtected(generateQrCodeoptions.error)
    useProtected(changePasswordOptions.error)
    useProtected(forgotPasswordOptions.error)

    const [resetEmail, setResetEmail] = useState(false)
    const [resetPassword, setResetPassword] = useState(false)
    const [showFullBirthDay, setShowFullBirthDay] = useState(false)
    const [modal, setModal] = useState(false)
    const [password, setPassword] = useState('')
    const [email, setEmail] = useState('')
    const [passwordError, setPasswordError] = useState(false)
    const [emailError, setEmailError] = useState(false)

    const setShowFullBirthDayModalHandler = () => {
        setModal(true)

    }

    const handleChanges = (prop) => (event) => {
        setPassword(event.target.value);
    };


    const handleSendNewPassword = async () => {
        if (password === "") {
            setPasswordError("Requried")
            return false
        }
        const result = await changePassword(userDetails._id)
        if (result?.data) {
            toast.success("New password sent successfully.")
            setPasswordError(false)
        }
    }
    const generateQRHandler = async () => {
        try {
            const result = await generateQrCode({ userId: userDetails._id });

            if (result?.data) {

                const qrCodeUrl = result.data.body.data;

                if (qrCodeUrl) {

                    const link = document.createElement('a');
                    link.href = qrCodeUrl;
                    link.download = `qr_code_${userDetails._id}.png`;


                    document.body.appendChild(link);
                    link.click();


                    document.body.removeChild(link);
                } else {
                    console.error('QR code data is not available');
                }
            }
        } catch (error) {
            console.error('Error generating QR code:', error);
        }
    };


    const handleSendResetEmail = async () => {
        ///===================== recapcha ==============================///
        window.grecaptcha.ready(async () => {
            try {
                const token = await window.grecaptcha.execute(`${process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY}`, { action: 'submit' });
                ///===================== recapcha ==============================///
                const data = {
                    email: userDetails.email ? userDetails.email.toLowerCase() : '',
                    token
                };
                const result = await forgotPassword(data)
                if (!result?.data?.error) {
                    toast.success("Password reset email sent successfully.")
                } else {
                    toast.error("Someting went wrong")
                }
            } catch (error) {
                console.error('Error:', error);
            }
        });
    }

    const ReadyCell = <StyledReadyCell><CheckCircleIcon sx={{ color: '#41AB37' }} />Ready</StyledReadyCell>


    //* =========================================  AcademicsRows ================================================== *//
    const academicsRows = [
        [
            {
                text: "School", TextStyle: { width: '120px', color: '#5C677D', }
            },
            { text: capitalizeWords(userDetails?.schoolName) || "No data available", align: "left" },
        ],
        [
            {
                text: "Student ID", TextStyle: { width: '120px', color: '#5C677D' }
            },
            {
                buttonRow: true,
                ButtonRowText: (userDetails?.userUniqueID) || "No data available",
                ButtonRowButtonText: 'Copy',
                ButtonRowButtonIcon: <ContentCopyIcon sx={{ fontSize: '16px !important' }} />,
            },
        ],
        userDetails?.userType === 'student' && ([
            {
                text: "NYSED ID #", TextStyle: { width: '120px', color: '#5C677D' }
            },
            {
                buttonRow: true,
                ButtonRowText: (userDetails?.nysedId) || "No data available",
                ButtonRowButtonText: 'Copy',
                ButtonRowButtonIcon: <ContentCopyIcon sx={{ fontSize: '16px !important' }} />,
            },
        ]),
        (userDetails?.userType === 'student') && (
            [
                {
                    text: "Current class/level", TextStyle: {
                        width: '120px',
                        color: '#5C677D'
                    }
                },
                { text: userDetails?.currentClassLevel || "No data available", align: "left" },
            ]
        )
        ,

        (userDetails?.userType === 'student') && (
            [
                {
                    text: "Started class/level", TextStyle: { width: '120px', color: '#5C677D' }
                },
                { text: userDetails?.startedClassLevel || "No data available", align: "left" },
            ]
        )
        ,
        (userDetails?.userType === 'student') && (
            [
                { text: "Started academic year", TextStyle: { width: '120px', color: '#5C677D' } },
                { text: userDetails?.academicYear || "No data available", align: "left" },
            ]
        )
        ,
        (userDetails?.userType === 'student') && (
            [
                { text: "Graduation Year", TextStyle: { width: '120px', color: '#5C677D' } },
                { text: userDetails?.graduationLevel && userDetails?.graduationYear && userDetails?.graduationLevel !== "" && userDetails?.graduationYear !== "" ? `${userDetails?.graduationLevel} - CLASS OF ‘${userDetails?.graduationYear?.slice(-2)}` : "No data available", align: "left" },
            ]
        )
        ,
        [
            { text: "Joined date", TextStyle: { width: '120px', color: '#5C677D' } },
            { text: moment(userDetails?.createdAt)?.format("DD-MMM-YYYY"), align: "left" },
        ],
        [
            {
                text: "QR Code", TextStyle: { width: '120px', color: '#5C677D' }
            },
            {
                buttonRow: true,
                ButtonRowText: userDetails?.qrCode ? ReadyCell : "No data available",
                ButtonRowButtonText: "download",
                buttonRowStatus: userDetails?.qrCode && true,
                ButtonRowOnClick: (e) => generateQRHandler(),
                ButtonRowButtonIcon: !userDetails?.qrCode && <FileDownloadOutlinedIcon sx={{ fontSize: '16px !important' }} />,
            },
        ],
        [
            { text: "Timezone", TextStyle: { color: '#5C677D' } },
            { text: userDetails?.timezones || "No data available", align: "left" },
        ],
    ]?.filter(Boolean);
    //* =========================================  AcademicsRows ================================================== *//

    //* =========================================  UserGeneralInfoRows ================================================== *//
    const tableRows = [
        [
            {
                text: "Name", TextStyle: {
                    width: '120px',
                    color: '#5C677D'
                }
            },
            { text: `${userDetails?.firstName} ${userDetails?.lastName}`, align: "left" },
        ],
        [
            {
                text: "Gender", TextStyle: {
                    width: '120px',
                    color: '#5C677D'
                }
            },
            { text: capitalizeWords(userDetails?.gender) || "No data available", align: "left" },
        ],
        [
            {
                text: "Birthday", TextStyle: {
                    width: '120px',
                    color: '#5C677D'
                }
            },
            {
                buttonRow: true,
                ButtonRowText: userDetails?.birthDate ? ((showFullBirthDay) ? moment(userDetails.birthDate).format("DD/MMM/YYYY") : `**/**/${moment(userDetails.birthDate).format("YYYY")}`) : "No data available",
                ButtonRowButtonText: !showFullBirthDay && "view",
                ButtonRowButtonIcon: !showFullBirthDay && <VisibilityOutlinedIcon />,
                buttonRowStatus: !showFullBirthDay && userDetails?.birthDate ? true : false,
                ButtonRowOnClick: (e) => setShowFullBirthDayModalHandler()
            },
        ],
    ];
    //* =========================================  UserGeneralInfoRows ================================================== *//


    //* =========================================  ContactRows ================================================== *//
    const parentUserContact = [
        [
            { text: "Cell/Mobile phone", TextStyle: { width: '120px', color: '#5C677D' } },
            {
                text: userDetails?.cellPhone
                    ? PhoneComponent(userDetails?.cellPhone) : "No data available",
                align: "left"
            },
        ],
        [
            { text: "Work phone", TextStyle: { width: '120px', color: '#5C677D' } },
            {
                text: userDetails?.workPhone
                    ? `${PhoneComponent(userDetails.workPhone)}${userDetails.ext ? ` ext ${userDetails.ext}` : ""}`
                    : "No data available",
                align: "left"
            },
        ],
        [
            { text: "Home phone", TextStyle: { width: '120px', color: '#5C677D' } },
            {
                text: userDetails?.homePhone
                    ? PhoneComponent(userDetails.homePhone)
                    : "No data available",
                align: "left"
            },
        ],
    ]
    const ContactRows = [
        [
            { text: "Email", TextStyle: { width: '120px', color: '#5C677D' } },
            {
                text: <span>{userDetails?.email || "No data available"}</span>,
                align: "left"
            },
        ],
        ...(userDetails?.userType !== 'parent'
            ? [
                [
                    { text: "Phone number", TextStyle: { width: '120px', color: '#5C677D' } },
                    {
                        text: userDetails?.phone ? PhoneComponent(userDetails?.phone) : "No data available",
                        align: "left"
                    },
                ]
            ]
            : parentUserContact),
        [
            { text: "Country", TextStyle: { width: '120px', color: '#5C677D' } },
            {
                text: userDetails?.country
                    ? countries?.find(country => country.value === userDetails.country)?.label : "No data available", align: "left"
            },
        ],
        [
            { text: "Address", TextStyle: { width: '120px', color: '#5C677D' } },
            {
                text: (
                    <span>
                        {
                            userDetails?.addressLine1 ? `${userDetails?.addressLine1 || ","}<br /> ${userDetails?.city || ","} ${userDetails?.state} ${userDetails?.zip}` : userDetails?.address ? userDetails?.address.label : "No data available"
                        }

                    </span>
                ),
                align: "left"
            },
        ],
    ];
    //* =========================================  ContactRows ================================================== *//

    //* =========================================  AccessDetailsRows ================================================== *//
    const AccessDetailsRows = [

        // { text: "Email", TextStyle: { color: '#5C677D' } },
        // { text: <span>qadhiashraful@qafys.org</span>, align: "left" },
        [
            { text: "Email", TextStyle: { width: '120px', color: '#5C677D' } },
            {
                menuRow: true,
                menuRowText: <span>{userDetails?.email}</span>,
                options: [
                    {
                        text: 'CHANGE EMAIL',
                        itemHandler: () => setResetEmail(true)
                    },
                ]
            },
        ],


        [
            { text: "Password", TextStyle: { width: '120px', color: '#5C677D' } },
            {
                menuRow: true,
                menuRowText: "********",
                options: [
                    {
                        text: 'Send New password',
                        itemHandler: () => setResetPassword(true)
                    },
                    {
                        text: 'Send Reset Email',
                        itemHandler: () => handleSendResetEmail()
                    },
                ]
            },
        ],

    ];
    //* =========================================  AccessDetailsRows ================================================== *//

    return (
        <>
            <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <InfoTable
                                tableHead={true}
                                tableHeadTitle={"PERSONAL DETAILS"}
                                tableRows={tableRows}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <InfoTable
                                tableHead={true}
                                tableHeadTitle={"CONTACT"}
                                tableRows={ContactRows}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <InfoTable
                                tableHead={true}
                                tableHeadTitle={"ACCESS DETAILS"}
                                tableRows={AccessDetailsRows}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} md={6}>
                    <InfoTable
                        tableHead={true}
                        tableHeadTitle={"ACADEMICS"}
                        tableRows={academicsRows}
                    />
                </Grid>
            </Grid>
            {/* ======= Email ====== */}
            <ModalThree
                open={resetEmail}
                handleClose={() => setResetEmail(false)}
                width="590px"
                Modaltitle="Change email address"
                titileColor="#33B651"
                padding="16px 0px 0px 0px"

                body={
                    <div>
                        <p style={{ textAlign: 'center' }}>Please confirm your password in order to change a user's email address.</p>
                        &nbsp;
                        <TextField
                            placeholder="New email address"
                            required={false}
                        // value={personalInfo?.lastName}
                        // handleChange={(val, e) => handleChanges("lastName")(e)}
                        // error={errors?.lastName}
                        // helperText={errors?.lastName}

                        />
                        &nbsp;
                        <TextField
                            placeholder="Confirm new email address"
                            required={false}
                            value={email}
                            handleChange={(val, e) => handleChanges("email")(e)}
                            error={emailError}
                            helperText={emailError}

                        />
                        &nbsp;
                        <Buttons
                            label="Confirm Change"
                            buttonColor="#5423FF"
                            border="#5423FF 1px solid"
                            color="#fff"
                            width="100%"
                            height="48px"
                            fontSize="14px"
                            link=""
                        // onClick={() => handleCheckPassword()}
                        />
                        <Buttons
                            label="Cancel"
                            buttonColor="#fff"
                            border="#fff 1px solid"
                            color="#5423FF"
                            width="100%"
                            height="48px"
                            fontSize="14px"
                            link=""
                        // onClick={handleCloseModal}
                        />
                    </div>
                }

            />
            {/* ======= password ====== */}
            <ModalThree
                open={resetPassword}
                handleClose={() => setResetPassword(false)}
                width="590px"
                Modaltitle="Enter your password"
                titileColor="#33B651"
                padding="16px 0px 0px 0px"

                body={
                    <div>
                        <p style={{ textAlign: 'center' }}>Please confirm your password in order to change a user's email address.</p>
                        &nbsp;
                        <TextField
                            placeholder="Confirm password"
                            required={false}
                            value={password}
                            handleChange={(val, e) => handleChanges("password")(e)}
                            error={passwordError}
                            helperText={passwordError}

                        />
                        &nbsp;
                        <Buttons
                            label="Continue"
                            buttonColor="#5423FF"
                            border="#5423FF 1px solid"
                            color="#fff"
                            width="100%"
                            height="48px"
                            fontSize="14px"
                            link=""
                            onClick={() => handleSendNewPassword()}
                        />
                        <Buttons
                            label="Cancel"
                            buttonColor="#fff"
                            border="#fff 1px solid"
                            color="#5423FF"
                            width="100%"
                            height="48px"
                            fontSize="14px"
                            link=""
                            onClick={() => setResetPassword(false)}
                        />
                    </div>
                }

            />
            <ModalThree
                handleClose={() => setModal(false)}
                open={modal}
                width="570px"
                Headerbackground="#fff"
                Headercolor="#676C7B"
                Footerbackground="#F7F9FA"
                ModalFooter={true}

                body={
                    <>
                        <EnteryourPassword
                            cancleButtonOperation={(e) => setModal(false)}
                            setShowFullBirthDay={setShowFullBirthDay}
                            showFullBirthDay={showFullBirthDay}
                        />
                    </>
                }

            />
        </>
    )
}

export default UserOverview