import * as React from 'react';
import { styled } from '@mui/material/styles';
import { Box, Button, IconButton, Menu, MenuItem, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';

const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
    "&.MuiPaper-root": {
        // boxShadow: 'none',
        boxShadow: "0px 0px 4px 0px rgba(0, 0, 0, 0.06)",
        border: '1px solid #E8EAED',
        borderRadius: '16px'
    }
}));

const StyledTableHead = styled(Box)(({ theme }) => ({
    padding: '16px 16px 0px 16px !important',
    "& .MuiBox-root": {
        padding: '16px 16px 0px 16px !important',
    },
    "& .MuiBox-root .tableHeaderTitle": {
        fontSize: '14px',
        fontWeight: 400,
        color: '#5C677D'
    }
    // borderBottom: '1px solid #E8EAED'
}))

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    padding: '14px 16px !important',
    // minWidth: '120px',
    "& .MuiTypography-root": {
        fontSize: '16px',
        fontWeight: 600
    }
}))

const StyledTableButton = styled(Button)(({ theme }) => ({
    padding: "0px 12px",
    margin: 0,
    color: '#5423FF',
    borderColor: '#E8EAED',
    borderRadius: '8px',
    "&:hover": { color: '#5423FF', borderColor: '#E8EAED' }
}))

const InfoTable = ({ tableHeader, tableHead, tableHeadTitle, tableRows }) => {
    // const [anchorEl, setAnchorEl] = React.useState(null);
    // const open = Boolean(anchorEl);
    // const handleClicks = (event) => {
    //     setAnchorEl(event.currentTarget);
    // };
    // const handleCloses = () => {
    //     setAnchorEl(null);
    // };

    const [menuAnchorEls, setMenuAnchorEls] = React.useState({});

    const handleClick = (event, rowIndex) => {
        setMenuAnchorEls((prev) => ({
            ...prev,
            [rowIndex]: event.currentTarget,
        }));
    };

    const handleClose = (rowIndex) => {
        setMenuAnchorEls((prev) => ({
            ...prev,
            [rowIndex]: null,
        }));
    };

    return (
        <>
            <Box>
                <StyledTableContainer component={Paper}>
                    {
                        tableHead && (
                            <StyledTableHead>
                                <Box>
                                    <p className='tableHeaderTitle'>{tableHeadTitle}</p>
                                </Box>
                            </StyledTableHead>
                        )
                    }
                    {/* <StyledTableContainer component={Paper}> */}
                    <Table aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                {
                                    tableHeader && tableHeader.map((item) => (
                                        <TableCell align={item?.align}>{item.title}</TableCell>
                                    ))
                                }
                            </TableRow>
                        </TableHead>

                        <TableBody>
                            {tableRows &&
                                tableRows.map((row, rowIndex) => (
                                    <TableRow key={rowIndex}>
                                        {row.map((cell, cellIndex) => (
                                            <StyledTableCell key={cellIndex} align={cell.align || "left"}>
                                                {
                                                    cell.text && <Typography sx={cell.TextStyle}>{cell.text}</Typography>
                                                }
                                                {
                                                    cell.component && cell.component
                                                }
                                                {
                                                    cell.buttonRow &&
                                                    (<Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }} onClick={cell.ButtonRowOnClick}>
                                                        <Typography>{cell.ButtonRowText}</Typography>
                                                        {
                                                            cell.buttonRowStatus &&
                                                            (<StyledTableButton
                                                                variant={cell.ButtonRowButtonVariant ? cell.ButtonRowButtonVariant : 'outlined'}
                                                                startIcon={cell.ButtonRowButtonIcon}
                                                                sx={cell.ButtonRowButtonStyles ? cell.ButtonRowButtonStyles : undefined}
                                                            >
                                                                {cell.ButtonRowButtonText}
                                                            </StyledTableButton>)
                                                        }

                                                    </Box>)
                                                }
                                                {
                                                    cell.menuRow &&
                                                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                                        <Typography>{cell.menuRowText}</Typography>
                                                        <IconButton
                                                            id={`menu-button-${rowIndex}`}
                                                            aria-controls={menuAnchorEls[rowIndex] ? 'basic-menu' : undefined}
                                                            aria-haspopup="true"
                                                            aria-expanded={menuAnchorEls[rowIndex] ? 'true' : undefined}
                                                            onClick={(event) => handleClick(event, rowIndex)}
                                                        >
                                                            <MoreVertIcon />
                                                        </IconButton>
                                                        <Menu
                                                            id={`menu-${rowIndex}`}
                                                            anchorEl={menuAnchorEls[rowIndex]}
                                                            open={Boolean(menuAnchorEls[rowIndex])}
                                                            onClose={() => handleClose(rowIndex)}
                                                            MenuListProps={{
                                                                'aria-labelledby': `menu-button-${rowIndex}`,
                                                            }}
                                                            transformOrigin={{
                                                                vertical: 'top',
                                                                horizontal: 'left',
                                                            }}
                                                        >
                                                            {cell.options && cell.options.map((item, optionIndex) => (
                                                                <MenuItem
                                                                    key={optionIndex}
                                                                    selected={item === 'Pyxis'}
                                                                    sx={{ color: '#5423FF', fontSize: '14px', fontWeight: 600 }}
                                                                    onClick={() => {
                                                                        item.itemHandler();
                                                                        handleClose(rowIndex);
                                                                    }}
                                                                >
                                                                    {item.text}
                                                                </MenuItem>
                                                            ))}
                                                        </Menu>
                                                        {/* <IconButton
                                                            id="basic-button"
                                                            aria-controls={open ? 'basic-menu' : undefined}
                                                            aria-haspopup="true"
                                                            aria-expanded={open ? 'true' : undefined}
                                                            onClick={handleClick}
                                                        >
                                                            <MoreVertIcon />
                                                        </IconButton>
                                                        <Menu
                                                            id="basic-menu"
                                                            anchorEl={anchorEl}
                                                            open={open}
                                                            onClose={handleClose}
                                                            MenuListProps={{
                                                                'aria-labelledby': 'basic-button',
                                                            }}
                                                            transformOrigin={{
                                                                vertical: 'top',
                                                                horizontal: 'left',
                                                            }}
                                                        >
                                                            {
                                                                cell.options && cell.options.map((item) => (
                                                                    <MenuItem
                                                                        selected={item === 'Pyxis'}
                                                                        sx={{
                                                                            color: '#5423FF',
                                                                            fontSize: '14px',
                                                                            fontWeight: 600
                                                                        }}
                                                                        onClick={() => {
                                                                            item.itemHandler();
                                                                            handleClose();
                                                                        }}>
                                                                        {item.text}
                                                                    </MenuItem>
                                                                ))
                                                            }
                                                        </Menu> */}
                                                    </Box>
                                                }
                                            </StyledTableCell>
                                        ))}
                                    </TableRow>
                                ))}
                        </TableBody>
                    </Table>
                </StyledTableContainer>
            </Box>
        </>
    )
}

export default InfoTable;

// * === Demo === * //

// const tableHeader = [
//     {
//         title: 'header one'
//     },
//     {
//         title: 'header two',
//         align: 'right'
//     }
// ]

// const tableRows = [
//     [
//         {
//             text: "Name", TextStyle: {
//                 color: '#5C677D'
//             }
//         },
//         { text: "Shakil Mahmud", align: "left" },
//     ],
//     [
//         {
//             text: "Gender", TextStyle: {
//                 color: '#5C677D'
//             }
//         },
//         { text: "Male", align: "left" },
//     ],
//     [
//         {
//             text: "Birthday", TextStyle: {
//                 color: '#5C677D'
//             }
//         },
//         {
//             buttonRow: true,
//             ButtonRowText: "**/**/1980",
//             ButtonRowButtonText: 'view',
//             ButtonRowButtonIcon: <></>,
//         },
//     ],
// [
//     { text: "Password", TextStyle: { color: '#5C677D' } },
//     {
//         menuRow: true,
//         menuRowText: "********",
//         options: [
//             {
//                 text: 'Send New password',
//                 itemHandler: () => alert('test')
//             },
//             {
//                 text: 'Send Reset Email',
//                 itemHandler: () => alert('test 2')
//             },
//         ]
//     },
// ],
// ];