import React, { useEffect, useState } from 'react';
import Table from '../../../../components/Table/Table';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import { useTransactionsSearchMutation } from '../../../../redux/features/transactions/transactionApi';
import useProtected from '../../../../hooks/useProtected';
import moment from 'moment-timezone';
import { Cycle } from '../../../../assets/imgs/icons/Cycle';
import Tooltip from '../../../../components/Form/Tooltip/Tooltip';
import QueryBuilderRoundedIcon from "@mui/icons-material/QueryBuilderRounded";



const RelatedPayments = ({ transactionsDetails }) => {
    const [getSearchDatas, getSearchDatasOptions] = useTransactionsSearchMutation()
    const [asc, setAsc] = useState(true);
    const [relatedPayments, setRelatedPayments] = useState([])
    const [rows, setRows] = useState([]);
    useProtected(getSearchDatasOptions?.error);

    const getTransactionsByfeesID = async (id) => {

        let data = {
            "cycle": [],
            "status": [],
            "dueDateStart": "",
            "dueDateEnd": "",
            "academicYearIds": [],
            "userUniqueID": [],
            "userType": "",
            "term": "",
            "feesnplanIds": id
        }

        try {

            const result = await getSearchDatas(data);
            if (!result?.data?.error) {
                setRelatedPayments(result?.data?.body?.data?.filter((item) => item?._id !== transactionsDetails?._id))

            }

        } catch (error) {
            console.error(error?.message);

        }
    }

    useEffect(() => {
        if (transactionsDetails?.planId) {
            getTransactionsByfeesID(transactionsDetails?.planId)
        }
    }, [transactionsDetails?.planId])

    useEffect(() => {
        if (relatedPayments?.length > 0) {
            setRows(convertDataToCellRows(relatedPayments));
        }

    }, [relatedPayments])

    const onClick = () => {
        alert('onclick working fine!');
    };
    ///=================================== sorting part start =============//
    const ascendingOrDescendingSorting = (field) => {
        let sortedPaymentDetails = [...relatedPayments]
        if (asc) {
            sortedPaymentDetails.sort((a, b) => a[field]?.localeCompare(b[field]));
        } else {
            sortedPaymentDetails.sort((a, b) => b[field]?.localeCompare(a[field]));
        }
        setRows(convertDataToCellRows(sortedPaymentDetails));
        setAsc(!asc)
    }
    ///=================================== sorting part end =============//

    const headCells = [
        { id: 'name', numeric: false, disablePadding: true, label: 'Amount', width: '20%', textAlign: 'left', },
        { id: 'status', numeric: true, disablePadding: false, label: 'status', width: '30%', textAlign: 'left', sort: true, headerOnClick: () => ascendingOrDescendingSorting('status'), },
        { id: 'cycle', numeric: true, disablePadding: false, label: 'Cycle', width: '20%', textAlign: 'left', sort: true, headerOnClick: () => ascendingOrDescendingSorting('cycle'), },
        { id: 'paymentDate', numeric: true, disablePadding: false, label: 'Date', width: '40%', textAlign: 'left', sort: true, headerOnClick: () => ascendingOrDescendingSorting('paymentDate') },
        { id: 'fat', numeric: true, disablePadding: false, label: '', width: '20%', textAlign: 'left', },

    ];

    const statustData = [
        {
            value: "success",
            statuscolor: "#EDF7EA",
            select: "Paid",
            textColor: "#66BD50",
        },
        {
            value: "active",
            statuscolor: "#EDF7EA",
            select: "Active",
            textColor: "#66BD50",
        },
        {
            value: "due",
            statuscolor: "#EDEDEF",
            select: "Due",
            textColor: "#676C7B",
        },
        {
            value: "pending",
            statuscolor: "#EDEDEF",
            select: "Pending",
            textColor: "#676C7B",
        },
        {
            value: "cancelled",
            statuscolor: "#FCE7E9",
            select: "Cancelled",
            textColor: "#E63946",
        },
        {
            value: "failed",
            statuscolor: "#FCE7E9",
            select: "Failed",
            textColor: "#E63946",
        },
    ];

    const convertDataToCellRows = (arr) => {
        if (arr?.length > 0) {
            return arr?.map((item) => {


                return {
                    cell: [
                        { text: `$${parseFloat(item?.amount)?.toFixed(2)}` || "N/A" },
                        {
                            Status: true,
                            statusLine: true,
                            linebackground: statustData?.find(status => status.value === item?.status)?.textColor,
                            background: statustData?.find(status => status.value === item?.status)?.statuscolor,
                            color: statustData?.find(status => status.value === item?.status)?.textColor,
                            label: statustData?.find(status => status.value === item?.status)?.select,
                        },

                        {
                            text:
                                item?.cycle === "onetime"
                                    ? "One-time"
                                    :
                                    <span className="paymentDateContainer">
                                        <Cycle fontSize="small" color="#7D8597" height="16px" />

                                        Monthly
                                    </span>
                                    || "N/A",
                        },
                        // { text: moment(item?.paymentDate)?.format("DD-MMM-YYYY") || "N/A" },
                        {
                            text: item?.paymentDate ? (
                                moment(item?.paymentDate)?.format("DD-MMM-YYYY")
                            ) : item?.cycle === "onetime" && item?.dueDate ? (
                                <span className="paymentDateContainer">
                                    {item?.paymentDate
                                        ? moment(item?.paymentDate)?.format("DD-MMM-YYYY")
                                        : moment(item?.createdAt)?.format("DD-MMM-YYYY")}
                                    <Tooltip
                                        TooltipText={
                                            <span>
                                                Upcoming payment scheduled:{" "}
                                                <b>{moment(item?.dueDate)?.format("DD-MMM-YYYY")}</b>
                                            </span>
                                        }
                                        Tooltip={<QueryBuilderRoundedIcon fontSize="small" />}
                                    />
                                </span>
                            ) : (
                                "N/A"
                            ),
                        },

                        { ActionButtonDotIcon: true, ActionButtonLabel: 'Action', TextAlign: "right" },

                    ],

                    //======= Action Date Start===== //
                    action: [
                        { label: 'View', onClick: onClick },
                        { label: 'Edit', onClick: onClick },
                        { label: 'Delete', onClick: onClick },
                    ],
                    //======= Action Date End===== //
                }
            });
        } else {
            return [];
        }
    };

    // const rows = [
    //     {
    //         cell: [
    //             { text: '$100', textcolor: "#001233", },
    //             { Status: true, background: '#EDEDEF', color: '#676C7B', linebackground: "#979DAC ", statusLine: true, fontSize: '12px', label: 'Due', },
    //             { text: 'Monthly', textcolor: "#001233", },
    //             { text: '13-Aug-2024', textcolor: "#001233", },
    //             { ActionButtonDotIcon: true, ActionButtonLabel: 'Action', TextAlign: "right" },
    //         ],

    //         //======= Action Date Start===== //
    //         action: [
    //             { label: 'View', onClick: onClick },
    //             { label: 'Edit', onClick: onClick },
    //             { label: 'Delete', onClick: onClick },
    //         ],
    //         //======= Action Date End===== //
    //     },

    //     {
    //         cell: [
    //             { text: '$100', textcolor: "#001233", },
    //             { Status: true, background: '#EDF7EA', color: '#001233', linebackground: "#41AB37", statusLine: true, fontSize: '12px', label: 'Paid', },
    //             { text: 'Monthly', textcolor: "#001233", },
    //             { text: '13-Aug-2024', textcolor: "#001233", },
    //             { ActionButtonDotIcon: true, ActionButtonLabel: 'Action', TextAlign: "right" },
    //         ],

    //         //======= Action Date Start===== //
    //         action: [
    //             { label: 'View', onClick: onClick },
    //             { label: 'Edit', onClick: onClick },
    //             { label: 'Delete', onClick: onClick },
    //         ],
    //         //======= Action Date End===== //
    //     },

    // ];

    return (
        <>
            <Table
                headCells={headCells}
                rows={rows}
                footer={true}
                innerHeader={true}
                innerTitle={<span style={{ fontSize: '20px' }}>Related payments</span>}
                RowsPerPage={10}

                emptyTableTitle="No users added yet"
                emptyTableNote="No items available"
                btnLabel="ADD USERS"
                showButton={true}
                btnLink="/invited-users"
            //onClickEmptyCard={handleOpenAttendanceEntry}
            />

        </>
    );
};

export default RelatedPayments;
